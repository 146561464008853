import React from 'react';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {getQueryUndefinedParamsObject, navigateToParametrized, getDomainWithPort, getQueryParam, getQueryParamNumber} from '../../../lib/url';
import {merge} from '../../../lib/object';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {TRADE} from '../../../constants/trade';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchCartMakeOrder} from '../../../backend/apiCalls';
import {GP_PAYMENT_TARGET} from '../../../constants/apiEndpoints';
import {PAYMENT_METHOD} from '../../../constants/paymentMethods';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { numberOrNull } from '../../../lib/number';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';

/**
 * @fero
 */

const orderQueryParams = [
    QUERY_PARAMS.ID_USER,
    QUERY_PARAMS.ID_CUSTOMER,
    QUERY_PARAMS.ID_INVOICE_ADDRESS,
    QUERY_PARAMS.ID_DELIVERY_ADDRESS,
    QUERY_PARAMS.ID_TRANSPORT_TYPE,
    QUERY_PARAMS.ID_PAYMENT_METHOD,
    QUERY_PARAMS.CUSTOMER_REFERENCE,
    QUERY_PARAMS.CUSTOMER_NOTICES,
    QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE,
    QUERY_PARAMS.ORDER_FORM_IS_CRITICAL,
    QUERY_PARAMS.CART_STEP,
    QUERY_PARAMS.ORDER_FORM_TRADE,
    QUERY_PARAMS.FINAL_ORDER_REFERENCE,
    QUERY_PARAMS.FINAL_CUSTOMER_NAME,
    QUERY_PARAMS.FINAL_USER_FULLNAME,
    QUERY_PARAMS.FINAL_USER_EMAIL,
    QUERY_PARAMS.FINAL_USER_PHONE,
    QUERY_PARAMS.FINAL_USER_LANGUAGE,
    QUERY_PARAMS.FINAL_ADDRESS_STREET,
    QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE,
    QUERY_PARAMS.FINAL_ADDRESS_CITY,
    QUERY_PARAMS.FINAL_ADDRESS_COUNTRY,
];

class SentOrder extends React.PureComponent {
    orderAttributes = () => {
        const {location} = this.props;
        const useProxy = getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_PROXY);
        return {
            id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
            id_invoice_address: getQueryParam(location, QUERY_PARAMS.ID_INVOICE_ADDRESS),
            id_delivery_address: getQueryParam(location, QUERY_PARAMS.ID_DELIVERY_ADDRESS),
            id_transport_type: getQueryParam(location, QUERY_PARAMS.ID_TRANSPORT_TYPE),
            id_payment_method: getQueryParam(location, QUERY_PARAMS.ID_PAYMENT_METHOD),
            customer_reference: getQueryParam(location, QUERY_PARAMS.CUSTOMER_REFERENCE),
            customer_notices: getQueryParam(location, QUERY_PARAMS.CUSTOMER_NOTICES),
            is_divisible: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE),
            is_critical: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_IS_CRITICAL),
            trade: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_TRADE),
            final_order: useProxy ? {
                order_reference: getQueryParam(location, QUERY_PARAMS.FINAL_ORDER_REFERENCE),
                customer: {
                    name: getQueryParam(location, QUERY_PARAMS.FINAL_CUSTOMER_NAME),
                },
                user: {
                    fullname: getQueryParam(location, QUERY_PARAMS.FINAL_USER_FULLNAME),
                    e_mail: getQueryParam(location, QUERY_PARAMS.FINAL_USER_EMAIL),
                    phone: getQueryParam(location, QUERY_PARAMS.FINAL_USER_PHONE),
                    id_language: getQueryParam(location, QUERY_PARAMS.FINAL_USER_LANGUAGE),
                },
                delivery_address: {
                    street: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_STREET),
                    zip_code: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE),
                    city: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_CITY),
                    id_country: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_COUNTRY)
                }
            } : undefined,
        };
    };

    navigateToOrderDetail = (orderId) => {
        const {location} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.CART_CURRENT]);
        navigateToParametrized(location, ROUTES.ORDER_DETAILS, merge(
            getQueryUndefinedParamsObject(orderQueryParams.filter(item => item != QUERY_PARAMS.ID_CUSTOMER)),
            {
                [QUERY_PARAMS.ID_ORDER]: orderId,
                [QUERY_PARAMS.ORDER_DETAIL_SHOW_PAYMENT_MESSAGE]: 1,
                [QUERY_PARAMS.REPORT_TO_GOOGLE]: undefined,
            }
        ));
    };

    navigateToPaymentGateway = (orderId) => {
        const url = getDomainWithPort() + GP_PAYMENT_TARGET + '?id_order=' + orderId;
        window.location.replace(url);
    };

    onFinish = (orderId) => {
        const {[GLOBAL_DATA.CART_CURRENT]: cartCurrent, 
            [SESSION_ATTRIBUTES.SETTINGS]: settings, 
            [SESSION_ATTRIBUTES.GTAG]: gtag, 
            location} = this.props;
        const trade = getQueryParam(location, QUERY_PARAMS.ORDER_FORM_TRADE);
        const paymentMethodId = getQueryParam(location, QUERY_PARAMS.ID_PAYMENT_METHOD);
        const reportToGoogle = getQueryParam(location, QUERY_PARAMS.REPORT_TO_GOOGLE);
        if(reportToGoogle) {
            gtag('event', 'purchase', {
                transaction_id: orderId,
                affiliation: settings.company_name,
                value: numberOrNull(cartCurrent.sum_netto),
                currency: settings.default_currency,
                tax: numberOrNull(cartCurrent.sum_brutto) - numberOrNull(cartCurrent.sum_netto),
            });
        }

        if((trade == null || trade == TRADE.SELL) && (paymentMethodId == PAYMENT_METHOD.GP_ONLINE_GATEWAY)) 
        {
            this.navigateToPaymentGateway(orderId);
        }
        else 
        {
            this.navigateToOrderDetail(orderId);
        }
    };

    render() {
        const orderAttributes = this.orderAttributes();
        const paymentMethod = orderAttributes.id_payment_method;
        return <div>
            <FormModal
                openNode={
                    <Button type="primary">
                        { orderAttributes.trade == TRADE.BUY ? 
                            <Trans>Uložiť objednávku</Trans> : 
                            <Trans>Odoslať objednávku s povinnosťou platby</Trans>
                        }
                    </Button>
                }
                values={{}}
                onFinishSuccessful={this.onFinish}
                title={<Trans>Odoslanie objednávky</Trans>}
                Form={generalNoParameterFormPC(
                    <React.Fragment>
                        <div>
                            <Trans>Chcete záväzne odoslať objednávku?</Trans>
                        </div>
                        {paymentMethod == PAYMENT_METHOD.GP_ONLINE_GATEWAY && <div>
                            <br/>
                            <Trans>Po potvrdení budete presmerovaný/á na platobnú bránu.</Trans>
                        </div>}
                    </React.Fragment>,
                    createFetchCartMakeOrder(orderAttributes),
                )}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa odoslať objednávku.`)}
            />
        </div>;
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.CART_CURRENT, GLOBAL_DATA.RELOAD_DATA])(
        withSessionHOC([SESSION_ATTRIBUTES.SETTINGS, SESSION_ATTRIBUTES.GTAG])(
            (SentOrder)
        )
    )
);